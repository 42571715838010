import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SupportCategory } from 'src/components/shared/ModalContactUs/ModalContactUs';
import { Toast } from 'src/components/shared/ToastManager';
import { Account } from 'src/interfaces/Account';
import { AccountType } from 'src/interfaces/AccountType';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import { Location } from 'src/interfaces/Location';
import { Organization } from 'src/interfaces/Organization';
import { ShoppingCart } from 'src/interfaces/ShoppingCart';
import { Supplier } from 'src/interfaces/Supplier';

interface InitialState {
  accounts: Account[] | null;
  accountTypes: AccountType[] | null;
  carts: ShoppingCart[];
  cartItems: ItemShoppingCart[];
  selectedLocation: Location | null;
  medicationSearch: string | null;
  organization: Organization | null;
  allSuppliers: Supplier[] | null;
  toast: Toast | null;
  contactUsOpen: boolean;
  contactUsCategory: SupportCategory | null;
}

const initialState: InitialState = {
  accounts: null,
  accountTypes: null,
  carts: [],
  cartItems: [],
  selectedLocation: null,
  medicationSearch: null,
  organization: null,
  allSuppliers: null,
  toast: null,
  contactUsOpen: false,
  contactUsCategory: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    // accounts
    setAccounts: (state, action: PayloadAction<Account[] | null>) => {
      state.accounts = action.payload;
    },
    setAccountTypes: (state, action: PayloadAction<AccountType[] | null>) => {
      state.accountTypes = action.payload;
    },
    // cart
    setCarts: (state, action: PayloadAction<ShoppingCart[]>) => {
      state.carts = action.payload;
    },
    setCartItems: (state, action: PayloadAction<ItemShoppingCart[]>) => {
      state.cartItems = action.payload;
    },
    // selectedLocation
    setLocation: (state, action: PayloadAction<Location | null>) => {
      state.selectedLocation = action.payload;
      if (action.payload?.location_id) {
        localStorage.setItem(
          'td-location-id',
          JSON.stringify(action.payload.location_id)
        );
      } else {
        localStorage.removeItem('td-location-id');
      }
    },
    // medicationSearch
    setMedicationSearch: (state, action: PayloadAction<string | null>) => {
      state.medicationSearch = action.payload;
    },
    // organization
    setOrganization: (state, action: PayloadAction<Organization | null>) => {
      state.organization = action.payload;
    },
    // suppliers
    setAllSuppliers: (state, action: PayloadAction<Supplier[] | null>) => {
      state.allSuppliers = action.payload;
    },
    // toast messages
    setToast: (state, action: PayloadAction<Toast | null>) => {
      state.toast = action.payload;
    },
    // "contact us" modal
    setContactUsOpen: (state, action: PayloadAction<boolean>) => {
      state.contactUsOpen = action.payload;
    },
    setContactUsCategory: (
      state,
      action: PayloadAction<SupportCategory | null>
    ) => {
      state.contactUsCategory = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const {
  setAccounts,
  setAccountTypes,
  setCarts,
  setCartItems,
  setLocation,
  setMedicationSearch,
  setOrganization,
  setAllSuppliers,
  setToast,
  setContactUsOpen,
  setContactUsCategory,
} = appSlice.actions;

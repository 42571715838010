import { AppUser } from 'src/interfaces/AppUser';
import { useAzureOnboardApi } from './useAzureOnboardApi';
import { useUtils } from './useUtils';
import { OnboardCreateUserDataContract } from 'src/interfaces/onboardApi/OnboardCreateUserDataContract';
import { useDispatch, useSelector } from 'react-redux';
import { AppUserSelf } from 'src/interfaces/AppUserSelf';
import {
  setAccounts,
  setAllSuppliers,
  setCartItems,
  setCarts,
  setLocation,
  setOrganization,
  setToast,
} from 'src/redux/appReducer';
import { useApi } from './useApi';
import { setUser, setUserLoading } from 'src/redux/authReducer';
import { AxiosError } from 'axios';
import { Location } from 'src/interfaces/Location';
import { Organization } from 'src/interfaces/Organization';
import { Supplier } from 'src/interfaces/Supplier';
import { Account } from 'src/interfaces/Account';
import { ShoppingCart } from 'src/interfaces/ShoppingCart';
import { ItemShoppingCart } from 'src/interfaces/ItemShoppingCart';
import {
  setSupplier,
  setSupplierLoading,
  setSupplierSettings,
  setSupplierSettingsLoading,
} from 'src/redux/supplierReducer';
import { SupplierSettings } from 'src/interfaces/SupplierSettings';
import {
  setOrganizationTypes,
  setOrganizationTypesLoading,
} from 'src/redux/adminReducer';

export const useEndpoints = () => {
  const utils = useUtils();
  const api = useApi();
  const azureOnboardApi = useAzureOnboardApi();
  const dispatch = useDispatch();

  // app state
  const authUser: AppUserSelf | null = useSelector(
    (state: any) => state.auth.user
  );
  const selectedLocation: Location | null = useSelector(
    (state: any) => state.app.selectedLocation
  );
  const organization: Organization | null = useSelector(
    (state: any) => state.app.organization
  );

  // supplier state
  const selectedSupplier: Supplier | null = useSelector(
    (state: any) => state.supplier.supplier
  );
  const supplierSettings: SupplierSettings | null = useSelector(
    (state: any) => state.supplier.settings
  );

  const endpoints = {
    /**
     *
     * @param user The user to be created
     * @param updateInB2c Determine whether it should update the "in_b2c" boolean
     * @param onSuccess Optional callback when successful
     * @param onError Optional call back when not successful
     * @returns
     */
    createB2cUser: async (
      user: AppUser,
      updateInB2c: boolean,
      onSuccess?: (response: any) => void,
      onError?: (response: any) => void
    ) => {
      const organizationName = authUser ? authUser.organization_name : '';
      const appName = 'Trulla Direct';
      const fullName = utils.getFullName(user);

      const userData: OnboardCreateUserDataContract = {
        firstName: user.first_name,
        lastName: user.last_name,
        userEmail: user.email_address,
        comments: `Created by Admin ${
          authUser ? utils.getFullName(authUser) : ''
        }`,
        userOnboardUrl: '', // leave empty
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
        sendFrom: 'noreply@trulladirect.com',
        senderName: appName,
        sendGridData: {
          onboardEmail: {
            templateId: process.env.REACT_APP_ONBOARD_EMAIL_TEMPLATE_ID || '',
            templateData: {
              username: fullName,
              applicationname: appName,
              organizationname: organizationName,
              url: process.env.REACT_APP_URL || '',
              subject: 'User Onboard Request',
            },
          },
          welcomeEmail: {
            templateId: process.env.REACT_APP_WELCOME_EMAIL_TEMPLATE_ID || '',
            templateData: {
              email: user.email_address,
              subject: 'Welcome to Trulla Direct',
              username: fullName,
              applicationname: appName,
              organizationname: organizationName,
              reseturl: process.env.REACT_APP_PASSWORD_RESET_URL, // "reseturl" property name is misleading. This link will correspond to a button that says "Go to Trulla Direct" in the email
            },
          },
          welcomeExternalEmail: {
            templateId:
              process.env.REACT_APP_WELCOME_EXTERNAL_EMAIL_TEMPLATE_ID || '',
            templateData: {
              email: user.email_address,
              subject: 'Welcome to Trulla Direct',
              username: fullName,
              applicationName: appName,
              organizationName: organizationName,
              reseturl: `${process.env.REACT_APP_URL}/login` || '',
            },
          },
          passwordReset: {
            templateId:
              process.env.REACT_APP_PASSWORD_RESET_EMAIL_TEMPLATE_ID || '',
            templateData: {
              email: user.email_address,
              subject: 'Welcome to Trulla Direct',
              username: fullName,
              applicationname: appName,
              organizationname: organizationName,
              reseturl: process.env.REACT_APP_PASSWORD_RESET_URL || '',
            },
          },
        },
        additionalProperties: {},
      };

      return await azureOnboardApi
        .post('onboarduser', userData)
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              setToast({
                message: 'Successfully created user',
              })
            );
            onSuccess && onSuccess(response);
            if (updateInB2c) {
              endpoints.updateUserInB2c(user);
            }
          } else if (response.status === 400) {
            dispatch(
              setToast({
                header: 'User Already Exists',
                color: 'danger',
                message: `This user already exists`,
                duration: -1,
                buttons: [
                  {
                    text: 'Close',
                    role: 'cancel',
                  },
                ],
              })
            );
            onError && onError(response);
          } else {
            dispatch(
              setToast({
                header: 'Error creating user',
                color: 'danger',
                message: `The user may already exist, or there may be a problem with our server. If this problem persists, please contact support using the "Help" option of the side menu.`,
                duration: -1,
                buttons: [
                  {
                    text: 'Close',
                    role: 'cancel',
                  },
                ],
              })
            );
            onError && onError(response);
          }
        });
    },
    updateUserInB2c: async (user: AppUser) => {
      return await api.put(`app_user/${user.app_user_id}/in_b2c`, {
        in_b2c: true,
      });
    },
    deleteB2cUser: async (
      user: AppUser,
      onSuccess?: (response: any) => void,
      onError?: (response: any) => void
    ) => {
      const formData = {
        userEmail: user.email_address,
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID || '',
      };
      azureOnboardApi.delete('deleteuser', formData).then((response) => {
        if (response.status === 200) {
          onSuccess && onSuccess(response);
        } else {
          onError && onError(response);
        }
      });
    },
    getAuthUser: () => {
      dispatch(setUserLoading(true));
      api
        .get('app_user/self')
        .then((response) => {
          // this response can be either a success or error response.
          if (response instanceof AxiosError) {
            // error
            // At this point, the user is authenticated through Azure B2C, but not in the application database.
          } else {
            dispatch(setUser(response.data));
          }
        })
        .finally(() => {
          dispatch(setUserLoading(false));
        });
    },
    getOrganization: () => {
      if (!authUser) return;
      api.get(`organization/${authUser.organization_id}`).then(({ data }) => {
        dispatch(setOrganization(data));
      });
    },
    getOrganizationTypes: () => {
      dispatch(setOrganizationTypesLoading(true));
      api
        .get('organization_types')
        .then((response) => {
          if (response.status === 200)
            dispatch(setOrganizationTypes(response.data));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          dispatch(setOrganizationTypesLoading(false));
        });
    },

    switchOrganizations: (organization: Organization) => {
      if (!authUser) return;
      if (authUser.organization_id === organization.organization_id) {
        dispatch(
          setToast({
            message: 'You already belong to that Organization.',
            color: 'primary',
            duration: 4000,
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
              },
            ],
          })
        );
        return;
      }
      api
        .put(`admin/app_user/${authUser.app_user_id}`, {
          organization_id: organization.organization_id,
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(setLocation(null));
            endpoints.getAuthUser();
            dispatch(
              setToast({
                header: 'Success! You now belong to:',
                message: organization.name,
                duration: 4000,
                buttons: [
                  {
                    text: 'Ok',
                    role: 'cancel',
                  },
                ],
              })
            );
          } else {
            dispatch(
              setToast({
                color: 'danger',
                header: 'Something went wrong',
                message: 'Unable to change organizations.',
                duration: 4000,
                buttons: [
                  {
                    text: 'Ok',
                    role: 'cancel',
                  },
                ],
              })
            );
          }
        });
    },

    getAllSuppliers: () => {
      api.get('suppliers').then((response) => {
        dispatch(setAllSuppliers(response.data));
      });
    },

    getSelectedLocation: () => {
      const locationId = localStorage.getItem('td-location-id');
      if (locationId) {
        api.get(`location/${locationId}`).then((response) => {
          if (response.status === 200) {
            dispatch(setLocation(response.data));
          } else {
            dispatch(setLocation(null));
          }
        });
      }
    },

    getAccounts: () => {
      if (!selectedLocation) {
        return;
      }
      api
        .get(`location/${selectedLocation.location_id}/accounts`)
        .then((response) => {
          if (response.status === 200) {
            const data: Account[] = response.data;
            dispatch(setAccounts(data));
          }
        });
    },

    getShoppingCarts: () => {
      if (!selectedLocation || (authUser && utils.isAnalyst(authUser))) {
        return;
      }
      api
        .get(`location/${selectedLocation.location_id}/shopping_carts`)
        .then((response) => {
          if (response.status === 200) {
            const data: ShoppingCart[] = response.data;
            dispatch(setCarts(data));
            endpoints.getShoppingCartItems();
          }
        });
    },

    getShoppingCartItems: () => {
      if (!selectedLocation) {
        return;
      }
      api
        .get(`location/${selectedLocation.location_id}/shopping_cart_items`)
        .then((response) => {
          if (response.status === 200) {
            const data: ItemShoppingCart[] = response.data;
            dispatch(setCartItems(data));
          }
        });
    },

    getSupplier: () => {
      if (!organization) {
        return;
      }
      dispatch(setSupplierLoading(true));
      api
        .get(`organization/${organization.organization_id}/supplier`)
        .then((response) => {
          if (response.status === 200) {
            const supplier: Supplier = response.data;
            dispatch(setSupplier(supplier));
          }
          dispatch(setSupplierLoading(false));
        })
        .catch((error) => {
          console.log(error);
          dispatch(setSupplierLoading(false));
        });
    },

    getSupplierSettings: () => {
      if (!selectedSupplier) {
        return;
      }
      dispatch(setSupplierSettingsLoading(true));
      api
        .get(`supplier/${selectedSupplier.supplier_id}/settings`)
        .then((response) => {
          if (response.status === 200) {
            const data: SupplierSettings = response.data;
            dispatch(setSupplierSettings(data));
          }
          dispatch(setSupplierSettingsLoading(false));
        })
        .catch((error) => {
          dispatch(setSupplierSettingsLoading(false));
        });
    },
  };

  return endpoints;
};

import { useAccount, useMsal } from '@azure/msal-react';
import { loginRequest } from 'src/config/authConfig';
import axios, { AxiosResponse } from 'axios';
import { MsalAccessToken } from 'src/interfaces/MsalAccessToken';
import { helpCircleOutline } from 'ionicons/icons';
import jwtDecode from 'jwt-decode';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setToast } from 'src/redux/appReducer';
import { setAccessToken, setSessionExpired } from 'src/redux/authReducer';

export const useApi = () => {
  const { instance, accounts: msalAccounts } = useMsal();
  const msalAccount = useAccount(msalAccounts[0] || {});
  const controllerRef = useRef(new AbortController());
  const dispatch = useDispatch();

  // This will return an axios response
  // OR an axios error
  const api = {
    get: (url: string, params?: any) => {
      return request('get', url, null, params);
    },
    post: (url: string, body?: any) => {
      return request('post', url, body);
    },
    put: (url: string, body?: any) => {
      return request('put', url, body);
    },
    delete: (url: string) => {
      return request('delete', url);
    },
  };

  const request = (
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    body?: any,
    params?: any
  ): Promise<AxiosResponse | any> => {
    if (isExpired()) {
      return instance
        .acquireTokenSilent({
          scopes: loginRequest.scopes,
          account: msalAccount || undefined,
        })
        .then((msalResponse) => {
          const token = msalResponse.accessToken;
          localStorage.setItem('td-token', token);
          const decoded = jwtDecode(token);
          dispatch(setAccessToken(decoded as MsalAccessToken));
          return apiRequest(method, url, token, body, params);
        })
        .catch((msalError) => {
          dispatch(setSessionExpired(true));
          // in case if silent token acquisition fails, fallback to an interactive method
        });
    } else {
      const token = localStorage.getItem('td-token');
      if (token) {
        const decoded = jwtDecode(token);
        dispatch(setAccessToken(decoded as MsalAccessToken));
      }
      return apiRequest(method, url, token, body, params);
    }
  };

  const apiRequest = (
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    token: string | null,
    body?: any,
    params?: any
  ) => {
    return axios.request({
      data: body,
      signal: controllerRef.current.signal,
      baseURL: process.env.REACT_APP_BASE_URL,
      method,
      url,
      params: params,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const isExpired = (): boolean => {
    const cachedToken = localStorage.getItem('td-token');
    if (!cachedToken) {
      return true;
    }
    const decoded: any = jwtDecode(cachedToken);
    const now = new Date();
    const expiresAt = new Date((decoded.exp - 60) * 1000); // subtract 1 minute, and convert to milliseconds
    return now >= expiresAt;
  };

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 403) {
        let message: string;
        if (response.config.method === 'get') {
          message = 'You lack permission to access that resource.';
        } else {
          message = 'You lack permission to perform that action.';
        }
        dispatch(
          setToast({
            header: 'Error: Unauthorized',
            message,
            color: 'danger',
            duration: 3000,
          })
        );
      }
      return response;
    },
    (error) => {
      if (error.response?.status === 404) {
        dispatch(
          setToast({
            message: `Error 404: not found "/${error.config.url}"`,
            color: 'danger',
            duration: 3000,
            icon: helpCircleOutline,
          })
        );
      } else if (error.response?.status === 500) {
        dispatch(
          setToast({
            header: 'Error 500',
            message: 'The server encountered an error',
            color: 'danger',
            duration: 3000,
          })
        );
      }
      return error;
    }
  );

  return api;
};
